import React from "react";
import "../styles.css";

export default function Form() {
  const apiMail = process.env.REACT_APP_MAIL_CODE;

  return (
    <section id="form">
      <div className="form--content">
        <div className="form--contact-us">
          <h3>Contáctanos</h3>
          <p>
            Te enviaremos una máquina <span className="bold">gratis</span>{" "}
            durante un fin de semana
          </p>
          <p>¡Tú decides si te gusta el producto!</p>
          <br />
          <p>Envíanos un mensaje por</p>
          <p>
            <a className="hover" href="tel:+34 609532559">
              <span className="bold">WhatsApp:</span> +34 609 53 25 59{" "}
            </a>
          </p>
          <p>
            <a className="hover" href="mailto:quotes@qrseven.com">
              <span className="bold">Email:</span> quotes@qrseven.com
            </a>
          </p>
        </div>

        <p>- O -</p>

        <div className="form--form">
          <p className="bold">Déjenos un mensaje aqui</p>
          <form action={`https://getform.io/f/${apiMail}`} method="POST">
            <div className="input">
              <label htmlFor="name">
                Nombre <span className="mandatory">*</span>
              </label>
              <input type="text" placeholder="Juan" name="name" required />
            </div>
            <input type="hidden" name="_gotcha" style={{ display: "none" }} />

            <div className="input">
              <label htmlFor="negocio">
                Nombre del Negocio <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                placeholder="Discoteca"
                name="negocio"
                required
              />
            </div>

            <div className="input">
              <label htmlFor="email">
                Email <span className="mandatory">*</span>
              </label>
              <input
                type="email"
                placeholder="email@gmail.com"
                name="email"
                required
              />
            </div>

            <div className="input">
              <label htmlFor="phone">
                Teléfono <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                placeholder="950327900"
                inputMode="numeric"
                name="phone"
                pattern="[0-9]+"
                required
              />
            </div>

            <div className="input">
              <label for="choice">
                Producto a elegir: <span className="mandatory">*</span>
              </label>
              <div className="form--option">
                <label className="hover">
                  <input
                    type="radio"
                    name="producto"
                    value="guardarropa"
                    required
                  />
                  Guardarropa
                </label>
              </div>

              <div className="form--option">
                <label className="hover">
                  <input type="radio" name="producto" value="guardarropa" />
                  Entradas
                </label>
              </div>

              <div className="form--option">
                <label className="hover">
                  <input type="radio" name="producto" value="guardarropa" />
                  Guardarropa y Entradas
                </label>
              </div>
            </div>

            <div className="center-button">
              <button type="submit">ENVIAR</button>
            </div>
          </form>
          <p className="center-text bold">
            Te responderémos en menos de 24 horas
          </p>
        </div>
      </div>
    </section>
  );
}
